import React from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  Box,
  Typography,
  styled,
  Select,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import HeaderNew from "../../landingpage/src/HeaderNew.web";
import { Footer } from "../../../components/src/Footer.web";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
export const configJSON = require("../../../framework/src/config");
import { AddImageIcon } from "../../customisableuserprofiles/src/assets";
import ProfileAddress from "../../customisableuserprofiles/src/ProfileAddress.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCartHeaderPart = () => {
    return (
      <CartHeaderContainer container alignItems="center" justifyContent="center">
        <Grid onClick={() => this.handleNextPage(1)} item xs={12} sm={12} md={12} lg={3} className={this.state.headerText === 1 ? "headerGrid headerGridBold" : "headerGrid"}>
          01.Cart
        </Grid>
        <Grid onClick={() => this.handleNextPage(2)} item xs={12} sm={12} md={12} lg={3} className={this.state.headerText === 2 ? "headerGrid headerGridBold" : "headerGrid"}>
          02.Address
        </Grid>
        <Grid onClick={() => this.handleNextPage(3)} item xs={12} sm={12} md={12} lg={3} className={this.state.headerText === 3 ? "headerGrid headerGridBold" : "headerGrid"}>
          03.Order Request
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} className={this.state.headerText === 4 ? "headerGrid headerGridBold" : "headerGrid"}>
          04.Invoice
        </Grid>
        <hr className="hrHeader" />
      </CartHeaderContainer>
    );
  };

  renderPriceDetailsPart = () => {
    return (
      <Box className="cartTextHeaderMainRight">
        <Box>
          <Typography className="priceHeadding">Price Details</Typography>
          <Typography className="priceCartItemType cartItemMargin">Cart Items: {this.formatNumberWithLeadingZero(this.state.orderPriceDetails.total_items)}</Typography>
          <hr className="hrPriceDetails" />
          <Box className="cartMainPrice">
            <Typography className="priceCartItemType">Total MRP</Typography>
            <Typography className="priceCartItemType">₹ {this.state.orderPriceDetails.sub_total}</Typography>
          </Box>
          <hr className="hrPriceDetails" />
          <Box className="cartMainPrice">
            <Typography className="priceCartItemType">GST</Typography>
            <Typography className="priceCartItemType">₹ {this.state.orderPriceDetails.total_tax}</Typography>
          </Box>
          <hr className="hrPriceDetails" />
          <Box className="cartMainPrice">
            <Typography className="priceCartItemType">Packaging and Freight extra as per applicable</Typography>
            <Typography className="priceCartItemType">₹ {this.state.orderPriceDetails.package_and_freight}</Typography>
          </Box>
          <hr className="hrPriceDetails" />
          {this.state.orderPriceDetails.promo_code_id &&
            <>
              <Box className="cartMainPrice">
                <Typography className="priceCartItemType">Applied Discount</Typography>
                <Typography className="priceCartItemType">₹ {this.state.orderPriceDetails.applied_discount}</Typography>
              </Box>
              <hr className="hrPriceDetails" />
            </>
          }
          <Box className="cartMainPrice">
            <Typography className="totalAmountType">Payable Amount</Typography>
            <Typography className="totalAmountType">₹ {this.state.orderPriceDetails.total_fees}</Typography>
          </Box>
        </Box>
        <Button
          data-test-id="nextBtnTestID"
          onClick={() => this.handleNextPage(0)}
          className="cartNextButton"
          disabled={this.state.orderData.length <= 0}
        >
          {this.state.headerText === 3 ? "Submit" : "Next"}
        </Button>
      </Box>
    );
  };

  renderCartPart = () => {
    return (
      <Box className="cartInnerRightLine">
        <Box className="headdingMainCart">
          <Typography className="cartHeadding">
            {this.state.headerText === 3 ? "Order Request" : "My Cart"}
          </Typography>
          {this.state.headerText !== 3 &&
            <Box
              className="newAddressButton"
              onClick={() => this.handleNavigateFromCart("Wishlist")}
              data-test-id="wishlistBtnTestID"
            >
              <img
                src={AddImageIcon}
                alt="changeImageicon"
                className="changeImageicon"
              />
              Add from Wishlist
            </Box>
          }
        </Box>
        {this.state.orderData.length > 0 ? (
          this.state.orderData.map((orderItem, index) => {
            const { name, stock_qty, part_number, images } = orderItem.attributes.catalogue.data.attributes
            const catalogueId = orderItem.attributes.catalogue.data.id
            const { total_price, total_gst, quantity, price } = orderItem.attributes
            const { id } = orderItem
            const stringWithoutFirstFourChars = part_number.substring(4)
            const quantityString = `Qty: ${quantity} Units`;
            return (
              <>
                <Box className="cartMainBox" key={index}>
                  <Box
                    data-test-id="cartInnerBoxTestID"
                    className="cartInnerBox"
                  >
                    {this.state.headerText === 3 && < Typography className="orderReqNumber"> {index + 1}.</Typography>}
                    <img src={configJSON.baseURL + images[0].url} alt="" className="imageCart" />
                    <Box className="sepratTwoMainBox">
                      <Box className="nameHeaderCart">
                        <Typography className="orderNumberTextCart">
                          {part_number}
                        </Typography>
                        <Typography className="modelCartText">
                          {name}
                        </Typography>
                        <Typography className="cartPartNUmberType">
                          Part Number - {stringWithoutFirstFourChars}
                        </Typography>
                        <Typography className="cartPartNUmberType">
                          GST - {total_gst}
                        </Typography>
                      </Box>
                      <Box className="nameHeaderCart">
                        <Typography className="orderNumberTextCart">
                          ₹ {total_price}
                        </Typography>
                        <Typography className="modelCartText perunit">
                          Price per unit ₹ {price}
                        </Typography>
                        <Autocomplete
                          value={quantityString}
                          id="controllable-states-demo"
                          options={this.generateOptions(stock_qty)}
                          data-test-id={"selectInputTestID" + index}
                          onChange={(event: React.ChangeEvent<{}>, value: string | null, reason: string) => this.handleChangeQuantity(event, value, reason, index, catalogueId)}
                          className="selectQuantity"
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                          forcePopupIcon={"auto"}
                        />
                      </Box>
                    </Box>
                    <HighlightOffIcon
                      data-test-id={`cancelIconTestID${index}`}
                      className="cancelIconCart"
                      onClick={() => {
                        this.deleteOrderItem(id, "");
                      }}
                    />
                  </Box>
                </Box >
              </>
            );
          })
        ) : (
          <Box className="cartMainBox" data-test-id="noDataBoxTestID">
            <Typography>No items available in the cart</Typography>
          </Box>
        )}
      </Box >
    );
  };

  renderAddressPart = () => {
    return (
      <>
        <ProfileAddress navigation={this.props.navigation} isOrderDetails={true} data-test-id="addressBoxTestID" />
        <Typography className="coupnCodeHeading">Have a coupon code?</Typography>
        <Box className="couponFeildMain">
          <TextField
            placeholder="Coupon code"
            className="couponCodeInputBox"
            id="outlined-basic"
            variant="outlined"
            value={this.state.couponCode}
            onChange={this.handleCouponInput}
            data-test-id="couponCodeInputTestID"
            disabled={this.state.orderPriceDetails.promo_code_id ? true : false}
          />
          <Button
            disabled={this.state.orderPriceDetails.promo_code_id || !this.state.couponCode ? true : false}
            className="ApplyButton"
            onClick={this.promoCodeApply}
            data-test-id="applyBtnTestID"
          >Apply</Button>
          {this.state.orderPriceDetails.promo_code_id &&
            <HighlightOffIcon
              data-test-id={`crossBtnTestID`}
              className="cancelCouponIconCart"
              onClick={this.promoCodeRemove}
            />
          }
        </Box>
      </>
    )
  }

  renderInvoicePart = () => {
    return (
      <Box data-test-id="invoiceInnerBoxTestID" className="cartInnerRightLine cartInnerRightLineInvoice">
        <Box className="headdingMainCart">
          <Typography className="cartHeadding">
            Order successfully submited
          </Typography>
        </Box>
        <Box className="invoiceInnerMain">
          <Box className="nameHeaderCart">
            <Typography className="orderNumberTextCart">
              {this.state.orderSubmitDetails.attributes.company_details.data.attributes.company_name}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              GST - {this.state.orderSubmitDetails.attributes.company_details.data.attributes.gst_number}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              PAN - {this.state.orderSubmitDetails.attributes.company_details.data.attributes.pan_card}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              {this.state.orderSubmitDetails.attributes.company_details.data.attributes.email} | {this.state.orderSubmitDetails.attributes.company_details.data.attributes.full_phone_number}
            </Typography>
          </Box>
          <Box
            className="newAddressButton downloadbtn"
            onClick={this.handleDownload}
            data-test-id="downloadBtnTestID"
          >
            <PlayForWorkIcon className="iconDownload" />
            Download order sheet
          </Box>
        </Box>
        <Box className="invoiceInnerMain">
          <Box className="nameHeaderCart">
            <Typography className="orderNumberTextCart invoiceHeadingChnage">
              Billed To
            </Typography>
            <Typography className="orderNumberTextCart">
              {this.state.orderSubmitDetails.attributes.order_address.name}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              {this.state.orderSubmitDetails.attributes.order_address.address} <br />
              {this.state.orderSubmitDetails.attributes.order_address.locality},{this.state.orderSubmitDetails.attributes.order_address.city},
              {this.state.orderSubmitDetails.attributes.order_address.state},{this.state.orderSubmitDetails.attributes.order_address.country}
            </Typography>
          </Box>
          <Box className="nameHeaderCart">
            <Typography className="orderNumberTextCart invoiceHeadingChnage">
              Billed Information
            </Typography>
            <Typography className="orderNumberTextCart">
              Amount : ₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.total_fees)}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              Invoice Number:&nbsp;&nbsp;&nbsp;&nbsp;{this.state.orderSubmitDetails.attributes.invoice_number}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              Invoice Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.formatDate(this.state.orderSubmitDetails.attributes.invoice_date)}
            </Typography>
            <Typography className="cartPartNUmberType invoiceAmmount">
              Due Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.formatDate(this.state.orderSubmitDetails.attributes.due_date)}
            </Typography>
          </Box>
        </Box>
        <TableContainer component={Paper} className="tableHead">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHeadding">Sno</TableCell>
                <TableCell align="center" className="tableHeadding">Description</TableCell>
                <TableCell align="center" className="tableHeadding">HSN/SANC</TableCell>
                <TableCell align="center" className="tableHeadding">GST Rate</TableCell>
                <TableCell align="center" className="tableHeadding">Quantity</TableCell>
                <TableCell align="center" className="tableHeadding">Unit</TableCell>
                <TableCell align="center" className="tableHeadding">Rate</TableCell>
                <TableCell align="center" className="tableHeadding">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.orderSubmitDetails.attributes.order_items.map((item, index) => (
                <TableRow key={1}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center" className="descriptionInvoiceHead">
                    <Box className="descriptionInvoice">
                      <Typography>{item.part_number}</Typography>
                      <Typography className="descriptionInvoicePacText">Packaginf and Freight {item.freight_package}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{item.hsn}</TableCell>
                  <TableCell align="center">{item.gst_rate}</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">{item.unit+"."}</TableCell>
                  <TableCell align="center">{this.numberWithCommas(item.price)}</TableCell>
                  <TableCell align="center">{this.numberWithCommas(item.total_price)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="invoiceBottom">
          <Box className="rightInvoice">
            <Box className="cartMainPrice invoiceMainUp">
              <Typography className="priceCartItemType">Sub total (excl. GST):</Typography>
              <Typography className="priceCartItemType">₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.sub_total)}</Typography>
            </Box>
            <Box className="cartMainPrice invoiceMainUp">
              <Typography className="priceCartItemType">Total GST:</Typography>
              <Typography className="priceCartItemType">₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.total_tax)}</Typography>
            </Box>
            <Box className="cartMainPrice invoiceMainUp">
              <Typography className="priceCartItemType">Total Packaging and Freight:</Typography>
              <Typography className="priceCartItemType">₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.total_freight_and_package)}</Typography>
            </Box>
            {this.state.orderSubmitDetails.attributes.order_details.discount_applied !== 0 &&
              <Box className="cartMainPrice invoiceMainUp">
                <Typography className="priceCartItemType">Applied Discount:</Typography>
                <Typography className="priceCartItemType">₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.discount_applied)}</Typography>
              </Box>
            }
            <Box className="cartMainPrice invoiceMain">
              <Typography className="priceCartItemType">Amount due on {this.formatDate(this.state.orderSubmitDetails.attributes.due_date)}:</Typography>
              <Typography className="priceCartItemType">₹ {this.numberWithCommas(this.state.orderSubmitDetails.attributes.order_details.total_fees)}</Typography>
            </Box>
          </Box>
        </Box>
      </Box >
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={{ width: "unset" , overflowX:'hidden' }}>
        <div className="mainContainer">
          <HeaderNew navigation={this.props.navigation} id={""} />
        </div>
        {this.renderCartHeaderPart()}
        {this.state.isLoading &&
          <CircularProgressBox >
            <CircularProgress className="loader" />
          </CircularProgressBox>
        }
        <MainCartContainer container>
          <Grid item xs={12} sm={12} md={12} lg={this.state.headerText === 4 ? 12 : 8} className="">
            <Box className="cartTextHeaderMain">
              {this.state.headerText === 1 && this.renderCartPart()}
              {this.state.headerText === 2 && this.renderAddressPart()}
              {this.state.headerText === 3 && this.renderCartPart()}
              {this.state.headerText === 4 && this.renderInvoicePart()}
            </Box>
          </Grid>
          {this.state.headerText !== 4 &&
            <Grid item xs={12} sm={12} md={12} lg={4} className="">
              {this.renderPriceDetailsPart()}
            </Grid>
          }
          <SnackBarAlert
            data-test-id="snackBarTestID"
            snackBarProps={this.state.hasSuccessErrorForCart}
            handleCloseSnack={this.handleCartCloseSnackbar}
          />
        </MainCartContainer>
        <Footer />
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CircularProgressBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  background: "#0000007a",
  zIndex: 99999,
  "& .loader": {
    color: "rgb(233, 184, 72)"
  }
});

const CartHeaderContainer = styled(Grid)({
  textTransform: "initial",
  "body": {
    padding: "0",
    overflow: "unset"
  },
  "& .headerGrid": {
    padding: "20px 0",
    textAlign: "center",
    fontSize: "large",
    cursor: "pointer",
  },
  "& .hrHeader": {
    color: "#F2F2F2",
    width: "100%",
  },
  "& .headerGridBold": {
    fontWeight: "bold",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
});
const MainCartContainer = styled(Grid)({
  textTransform: "initial",
  "& .tableHead": {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  "& .descriptionInvoice": {
    width: "200px",
    textAlign: "left",
  },
  "& .descriptionInvoicePacText": {
    fontSize: "12px",
    opacity: "0.5",
    fontWeight: "400"
  },
  "& .tableHeadding": {
    opacity: "0.5",
    fontWeight:"600",
  },
  "& .descriptionInvoiceHead": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "unset",
  },
  "& .invoiceInnerMain": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .invoiceBottom": {
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .rightInvoice": {
    width: "50%",
    boxShadow: "0px 0px 0px 2px rgb(240, 240, 240)",
    padding: "20px 0 0 0",
    flexDirection: "column",
    display: "flex",
    gap: "20px",
  },
  "& .invoiceMain": {
    padding: "20px 15px",
    background: "rgb(233, 184, 72)"
  },
  "& .invoiceMainUp": {
    padding: "0px 15px",
  },
  "& .invoiceMain p": {
    opacity: "unset !important",
  },
  "& .invoiceMainUp p": {
    opacity: "unset !important",
    fontWeight: "500"
  },
  "& .MuiButton-root.Mui-disabled": {
    opacity: "0.6"
  },
  "& .ApplyButton": {
    marginLeft: "12px",
    height: "fit-content",
    width: "120px",
    border: "none",
    background: "#000",
    fontSize: "16px",
    color: "#ffff",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontStyle: "normal",
    borderRadius: "8px",
    lineHeight: "normal",
    textTransform: "initial",
    padding: "10px 16px",
    "&:hover": {
      background: "Black",
    },
    "& .MuiButton-root": {
      textTransform: "initial"
    },
  },
  "& .couponCodeInputBox": {
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: "4px !important",
      padding: "unset !important",
    },
    width: "320px",
    marginTop: "12px",
    border: "none",
    background: "transparent",
    fontFamily: "Montserrat",
    color: "#64748B",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      border: "none"
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-root": {
      marginBottom: "10px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid black",
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid black",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
  },
  "& .cancelCouponIconCart": {
    cursor: "pointer",
    marginLeft: "12px",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .couponFeildMain": {
    display: "flex",
    alignItems: "center"
  },
  "& .coupnCodeHeading": {
    marginTop: "32px",
    fontSize: "16px",
    opacity: "0.6"
  },
  "& .selectQuantity": {
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: "4px !important",
      padding: "unset !important",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      display: "none"
    },
    width: "140px",
    background: "rgb(233, 184, 72)",
    border: "none",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "24px",
    height: "35px",
    "& .MuiOutlinedInput-input": {
      fontSize: "12px",
      color: "#000",
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-root": {
      marginBottom: "10px",
    },
  },
  "& .cartNextButton": {
    color: "#ffff",
    height: "52px",
    fontFamily: "Montserrat",
    width: "100%",
    border: "none",
    textTransform: "initial",
    background: "#000",
    lineHeight: "normal",
    fontSize: "16px",
    borderRadius: "8px",
    fontStyle: "normal",
    padding: "10px 16px",
    fontWeight: 400,
    marginTop: "44px",
    "&:hover": {
      background: "Black",
    },
    "@media (min-width:320px) and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .cartMainPrice": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .changeImageicon": {
    width: "20px",
    display: "flex",
    justifyContent: "center",
    height: "20px",
    marginRight: "10px",
    alignItems: "center",
  },
  "& .downloadbtn": {
    background: "#000 !important",
    color: "#FFF !important",
    fontSize: "18px !important",
  },
  "& .iconDownload": {
    color: "#FFF !important",
    height: "25px",
    width: "25px"
  },
  "& .newAddressButton": {
    border: "1px solid #000",
    background: "#FFF",
    color: "#000",
    width: "fit-content",
    borderRadius: "8px",
    height: "fit-content",
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "normal",
    fontWeight: 400,
    padding: "8px 15px",
    textTransform: "initial",
    cursor: "pointer",
    display: "flex",
    "@media (min-width:320px) and (max-width:1000px)": {
      justifyContent: "center",
      width: "100%",
    },
  },
  "& .sepratTwoMainBox": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 90px 0 36px",
    flexWrap: "wrap",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .imageCart": {
    width: "140px",
    height: "100px",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .orderReqNumber": {
    fontSize: "18px",
    padding: "0 16px",
    "@media (min-width:320px) and (max-width:575px)": {
    },
  },
  "& .cancelIconCart": {
    position: "absolute",
    right: "0",
    top: "0",
    cursor: "pointer",
    "@media (min-width:320px) and (max-width:575px)": {
      right: "0",
      top: "0",
      fontSize: "16px"
    },
  },
  "& .totalAmountType": {
    fontWeight: 800,
    fontStyle: "normal",
    color: "#000",
    zIndex: 99,
    fontSize: "16px",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .hrPriceDetails": {
    opacity: "0.6"
  },
  "& .cartItemMargin": {
    marginBottom: "20px"
  },
  "& .priceCartItemType": {
    fontWeight: 700,
    fontStyle: "normal",
    color: "#000",
    zIndex: 99,
    fontSize: "12px",
    opacity: "0.6",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .cartPartNUmberType": {
    fontWeight: 700,
    fontStyle: "normal",
    color: "#000",
    zIndex: 99,
    fontSize: "12px",
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .invoiceAmmount": {
    fontWeight: 500,
  },
  "& .modelCartText": {
    color: "#000",
    fontSize: "12px",
    fontWeight: 700,
    zIndex: 99,
    fontStyle: "normal",
    opacity: 0.5,
    "@media (min-width:320px) and (max-width:575px)": {
      wordBreak: "break-all"
    },
  },
  "& .perunit": {
    opacity: 0.7,
    fontSize: "10px",
  },
  "& .orderNumberTextCart": {
    position: "relative",
    fontSize: "20px",
    zIndex: 99,
    color: "#000",
    alignItems: "center",
    display: "flex",
    fontStyle: "normal",
    fontWeight: 700,
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "12px !important",
    },
  },
  "& .invoiceHeadingChnage": {
    fontSize: "16px",
    color: "rgb(233, 184, 72)",
    fontWeight: "unset",
  },
  "& .nameHeaderCart": {
    flexDirection: "column",
    display: "flex",
    gap: "5px",
  },
  "& .cartMainBox": {
    boxShadow: "0px 0px 8px -5px rgb(0, 0, 0)",
    borderRadius: "20px",
    justifyContent: "center",
    padding: "20px 15px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    "@media (min-width:320px) and (max-width:575px)": {
      margin: "0 0px 0 0",
    },
  },
  "& .cartInnerBox": {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  "& .cartTextHeaderMainRight": {
    padding: "98px 16% 53px 0%",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 4% 0px",
      widht:'100%'
    },
  },
  "& .cartTextHeaderMain": {
    padding: "50px 8% 53px",
    "@media (min-width:320px) and (max-width:575px)": {
      padding: "0px 4% 0px",
    },
  },
  "& .headdingMainCart": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 28px 0",

    "@media (min-width:320px) and (max-width:575px)": {
      margin: "0 0px 28px 0",
      flexDirection: "column",
      padding:'10px'
    },
  },
  "& .priceHeadding": {
    fontSize: "26px",
    position: "relative",
    color: "#000",
    zIndex: 99,
    fontWeight: 800,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
  "& .cartHeadding": {
    fontSize: "40px",
    position: "relative",
    color: "#000",
    zIndex: 99,
    fontWeight: 500,
    fontStyle: "normal",
    "@media (min-width:320px) and (max-width:800px)": {
      fontSize: "24px !important",
      marginBottom: "12px"
    },
  },
  "& .cartInnerRightLine": {
    width: "100%",
  },
  "& .cartInnerRightLineInvoice": {
    display: "flex",
    flexDirection: "column",
    gap: "45px",
  },
  "@media (min-width:320px) and (max-width:575px)": {
    padding: "0px 0% 0px",
  },
});
// Customizable Area End
